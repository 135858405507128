import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import MembershipApiService from "@/core/services/api.service.membership";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "package-permission",
    data() {
        return {
            valid: false,
            isSaving: false,
            packagePermissionId: null,
            membershipApiInitiated: false,
            selectedInstallmentDuration: null,
            labelEng: null,
            descriptionEng: null,
            labelMne: null,
            descriptionMne: null,
            labelGer: null,
            descriptionGer: null,
            externalIds: ["GENERAL_PERMISSION", "SHOPPING_EVENT", "SHOPPING_WITH_FRIENDS", "CITY_SHOPPING", "WALLET", "SHOP_PROFILE", "HERO_CONTENT"],
            packagePermission: {
                externalId: "",
                ordinalNumber: null,
                translations: [
                    {
                        id: null,
                        lang: "",
                        label: "",
                        description: ""
                    }
                ]
            },
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    async created() {
        this.$log.debug("await init memb service - start");
        await MembershipApiService.init();
        this.$log.debug("await init memb service - end");
        this.membershipApiInitiated = true;

        this.packagePermissionId = this.$route.params.packagePermissionId;
        let title =
            this.packagePermissionId != null
                ? "Package permission details"
                : "Insert new package permission";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        if (this.packagePermissionId != null) {
            MembershipApiService.get(`/CatalogItems/GetPermissionDefinition`, `${this.packagePermissionId}`)
                .then(response => {
                    this.$log.debug("Permission: ", response.data);
                    this.packagePermission = response.data;

                    let existingEngTrans = this.packagePermission.translations.filter(obj => {
                        return obj.lang === "ENG";
                    })[0]
                    if (existingEngTrans != null) {
                        this.labelEng = existingEngTrans.label;
                        this.descriptionEng = existingEngTrans.description;
                    }

                    let existingMneTrans = this.packagePermission.translations.filter(obj => {
                        return obj.lang === "MNE";
                    })[0]
                    if (existingMneTrans != null) {
                        this.labelMne = existingMneTrans.label;
                        this.descriptionMne = existingMneTrans.description;
                    }


                    let existingGerTrans = this.packagePermission.translations.filter(obj => {
                        return obj.lang === "GER";
                    })[0]
                    if (existingGerTrans != null) {
                        this.labelGer = existingGerTrans.label;
                        this.descriptionGer = existingGerTrans.description;
                    }


                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
    },
    methods: {
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.savePermission(this.packagePermission);
            }
        },
        savePermission(permission) {
            this.isSaving = true;
            this.loading = true;
            let translations = [];

            let translationEng = {};
            let existingEngTrans = permission.translations.filter(obj => {
                return obj.lang === "ENG";
            })[0]
            if (existingEngTrans != null) translationEng['id'] = existingEngTrans.id;
            translationEng['lang'] = "ENG";
            translationEng['label'] = this.labelEng;
            translationEng['description'] = this.descriptionEng;
            translations.push(translationEng);

            let translationMne = {};
            let existingMneTrans = permission.translations.filter(obj => {
                return obj.lang === "MNE";
            })[0]
            if (existingMneTrans != null) translationEng['id'] = existingMneTrans.id;
            translationMne['lang'] = "MNE";
            translationMne['label'] = this.labelMne;
            translationMne['description'] = this.descriptionMne;
            translations.push(translationMne);

            let translationGer = {};
            let existingGerTrans = permission.translations.filter(obj => {
                return obj.lang === "GER";
            })[0]
            if (existingGerTrans != null) translationGer['id'] = existingGerTrans.id;
            translationGer['lang'] = "GER";
            translationGer['label'] = this.labelGer;
            translationGer['description'] = this.descriptionGer;
            translations.push(translationGer);

            permission.translations = translations;
            console.log("translations: " + translations);

            if (permission.id != null) {
                MembershipApiService.post(`/CatalogItems/UpdateShopPermissionDefinitions`, permission)
                    .then(response => {
                        this.$log.debug("Package permission updated: " + response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            } else {
                MembershipApiService.post(`/CatalogItems/CreateShopPermissionDefinition`, permission)
                    .then(response => {
                        this.$log.debug("Package permission created: " + response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
